import { BaseResource } from "@/resources/base-resource";
import {
  IInvoiceCreationRequest,
  IInvoice,
  IInvoiceNotificationRequest
} from "@/data/invoice";
import { IReplenishmentPaymentData } from "@/data/replenishment";

export default class InvoicesResource {
  public static create(request: IInvoiceCreationRequest): Promise<IInvoice> {
    return BaseResource.tryPost(InvoiceUrls.Invoice, request);
  }

  public static get(guid: string): Promise<IInvoice> {
    return BaseResource.tryGet(`${InvoiceUrls.Invoice}/${guid}`);
  }

  public static notificate(request: IInvoiceNotificationRequest) {
    return BaseResource.tryPut(
      `${InvoiceUrls.InvoiceNotification.replace(
        "{guid}",
        request.invoiceGuid
      )}`,
      request
    );
  }
  public static getInvoicePaymentData(
    guid: string
  ): Promise<IReplenishmentPaymentData> {
    return BaseResource.tryGet(
      `${InvoiceUrls.InvoicePaymentData.replace("{guid}", guid)}`
    );
  }

  public static getQrCode(queryUrl: string): Promise<any> {
    return BaseResource.tryGet(
      InvoiceUrls.QrCode,
      {
        url: queryUrl
      },
      undefined,
      {
        responseType: "blob"
      }
    );
  }
}

enum InvoiceUrls {
  Invoice = "/v1/invoices",
  InvoiceNotification = "/v1/invoices/{guid}/send-notification",
  InvoicePaymentData = "/v1/invoices/{guid}/payment-data",
  QrCode = "/v1/wallets/qr-code"
}

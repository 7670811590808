import {
  IReplenishment,
  ProcessPayment,
  ReplenishmentHandler,
  ReplenishmentStatus,
  UserDeviceType
} from "@/data/replenishment";
import InvoicesResource from "@/resources/invoices-resource";

export interface IInvoiceCreationRequest {
  amount: string;
  comment: string;
  backUrlFormat: string;
  handler: ReplenishmentHandler;
  userDeviceType: UserDeviceType;
}

export interface IInvoiceNotificationRequest {
  email: string;
  invoiceGuid: string;
  url: string;
}

export interface IInvoice {
  replenishment: IReplenishment;
  recepient: string;
  walletNumber: string;
  id: number;
  guid: string;
  amount: string;
  comment: string;
}

export class InvoiceForm implements IInvoiceCreationRequest {
  amount = "";
  comment = "";
  backUrlFormat = `${window.location.origin}/#/invoice-success/{guid}`;
  handler = ReplenishmentHandler.internal;
  userDeviceType = UserDeviceType.webBrowser;
}

export class InvoiceStatus {
  status: ReplenishmentStatus;
  walletNumber: string = "";
  comment: string = "";
  amount: string = "";
  guid: string = "";
  currency = "₽";

  get url(): string {
    return `${window.location.origin}/#/invoice-payment/${this.guid}`;
  }
  get paymentUrl(): string {
    return `${window.location.origin}/#/invoice-redirect/${this.guid}`;
  }
  constructor(s?: IInvoice) {
    this.status = ReplenishmentStatus.initialization;
    if (s) {
      this.status = s.replenishment.status;
      this.walletNumber = s.walletNumber;
      this.comment = s.comment;
      this.guid = s.guid;
      this.amount = s.amount;
    }
  }
  get isPaymentDone(): boolean {
    return (
      this.status === ReplenishmentStatus.completed ||
      this.status === ReplenishmentStatus.failed
    );
  }
  get isPaymentStarted(): boolean {
    return this.status !== ReplenishmentStatus.initialization;
  }
  get statusText(): string {
    switch (this.status) {
      case ReplenishmentStatus.completed:
        return "Счет оплачен";
      case ReplenishmentStatus.failed:
        return "Ошибка платежа";
    }
    return "Обработка платежа";
  }
  get statusMessage(): string {
    switch (this.status) {
      case ReplenishmentStatus.completed:
        return "Успешно!";
      case ReplenishmentStatus.failed:
        return "Платеж не удался. Получите новый запрос на перевод";
    }
    return "";
  }
  getNotificationRequest(email: string): IInvoiceNotificationRequest {
    return {
      email: email,
      invoiceGuid: this.guid,
      url: this.url
    };
  }

  async tryPay(): Promise<void> {
    await InvoicesResource.getInvoicePaymentData(this.guid).then(async res => {
      const payment = new ProcessPayment(res);
      await payment.submit();
    });
  }
}

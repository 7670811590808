

















import { Vue, Component } from "vue-property-decorator";
import { InvoiceStatus } from "@/data/invoice";
import InvoicesResource from "@/resources/invoices-resource";

@Component
export default class InvoiceSuccessView extends Vue {
  invoice: InvoiceStatus | null = null;
  invoiceInterval: number | null = null;
  loading = false;

  beforeDestroy() {
    if (this.invoiceInterval) {
      clearInterval(this.invoiceInterval);
    }
  }
  async created(): Promise<void> {
    await this.loadInvoice();
    this.invoiceInterval = setInterval(this.reloadInvoice, 5000);
  }
  async reloadInvoice(): Promise<void> {
    const code = this.$route.params.code;

    await InvoicesResource.get(code).then(res => {
      this.invoice = new InvoiceStatus(res);
      if (this.invoice.isPaymentDone && this.invoiceInterval) {
        clearInterval(this.invoiceInterval);
      }
    });
  }

  async loadInvoice(): Promise<void> {
    this.loading = true;
    try {
      await this.reloadInvoice();
    } finally {
      this.loading = false;
    }
  }
}
